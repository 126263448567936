<template>
  <div class="data-interaction">
    <div class="data-interaction__buttons" ref="buttons" v-if="buttonsVisible">
      <Button class="button--small" @click.native="onRetryClick()"
        >RETRY</Button
      >
      <Button class="button--small" @click.native="onDownloadClick()"
        >DOWNLOAD</Button
      >
    </div>

    <div class="data-interaction__stage">
      <div class="data-interaction__intro" ref="intro">
        <h1>How has your life gone so far?</h1>
        <p>
          Fill in your age and find out how much time you have already spent on
          everyday activities.
        </p>

        <h2>Whats your gender?</h2>
        <GenderToggle @toggle="onGenderToggle" />
        <input id="age" ref="input" placeholder="Insert your age" />
        <Button class="button--outline" @click.native="calculate"
          >CONFIRM</Button
        >
      </div>

      <!--<div
        class="data-interaction__item"
        v-for="(item, index) in contents"
        :key="index"
      >
        {{ getDataLabel(item) }}
      </div>-->

      <div class="data-interaction__illustration">
        <svg
          id="illustration"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 900 1024"
          ref="illustration"
        >
          <defs>
            <linearGradient
              id="grad1"
              x1="0"
              y1="0"
              x2="100%"
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stop-color="#fac334" />

              <stop offset="0.09090909090909091" stop-color="#f9bd31" />

              <stop offset="0.18181818181818182" stop-color="#f8b62f" />

              <stop offset="0.2727272727272727" stop-color="#f6b02d" />

              <stop offset="0.36363636363636365" stop-color="#f5aa2b" />

              <stop offset="0.4545454545454546" stop-color="#f3a329" />

              <stop offset="0.5454545454545454" stop-color="#f19d28" />

              <stop offset="0.6363636363636364" stop-color="#ef9727" />

              <stop offset="0.7272727272727273" stop-color="#ed9127" />

              <stop offset="0.8181818181818182" stop-color="#eb8a26" />

              <stop offset="0.9090909090909092" stop-color="#e98426" />

              <stop offset="1" stop-color="#e67e26" />
            </linearGradient>

            <filter id="dropshadow" height="130%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
              <!-- stdDeviation is how much to blur -->
              <feOffset dx="2" dy="2" result="offsetblur" />
              <!-- how much to offset -->
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.5" />
                <!-- slope is the opacity of the shadow -->
              </feComponentTransfer>
              <feMerge>
                <feMergeNode />
                <!-- this contains the offset blurred image -->
                <feMergeNode in="SourceGraphic" />
                <!-- this contains the element that the filter is applied to -->
              </feMerge>
            </filter>

            <filter id="shadow">
              <feDropShadow dx="0" dy="0" stdDeviation="3" />
            </filter>

            <clipPath id="cp">
              <polygon
                points="66.2 408.7 393.8 408.7 374.8 364.6 66.2 364.6 66.2 408.7"
                fill="#fff"
              />
              <polygon
                points="66.9 272.8 335.8 272.8 317.6 230.6 66.9 230.6 66.9 272.8"
                fill="#fff"
              />
              <polygon
                points="81.6 286.2 66.8 299.2 66.8 340.3 81.6 351.4 370.3 356.6 371.8 356.6 357.5 323.3 108.4 322.7 108.4 315 353.6 314.3 339.5 281.6 82 286.2 81.6 286.2"
                fill="#fff"
              />
              <polygon
                points="107.7 462.8 416.8 462.8 398.6 420.6 66.1 420.6 66.1 476.2 107.7 476.2 107.7 462.8"
                fill="#fff"
              />
              <polygon
                points="66.7 567.8 462.8 567.8 444.6 525.6 66.7 525.6 66.7 567.8"
                fill="#fff"
              />
              <polygon
                points="508.8 675.5 489.8 631.4 108 631.4 108 621.8 485.6 621.8 467.5 579.6 81.2 579.6 66.4 594.4 66.4 658.8 81.2 675.5 508.8 675.5"
                fill="#fff"
              />
              <polygon
                points="571.8 821.5 552.8 777.4 108.2 777.4 108.2 767.8 548.6 767.8 530.5 725.6 81.4 725.6 66.6 740.4 66.6 804.8 81.4 821.5 571.8 821.5"
                fill="#fff"
              />
              <polygon
                points="598 885.3 266.1 885.3 266.1 873.8 593.1 873.8 575.8 833.6 67.4 833.6 67.4 873.8 224.1 873.8 224.1 910.8 240.7 927.4 616.3 927.4 598 885.3"
                fill="#fff"
              />
              <polygon
                points="265.6 109.6 66.9 109.6 66.9 151.7 283.8 151.7 265.6 109.6"
                fill="#fff"
              />
              <polygon
                points="293.6 176.6 66 176.6 66 218.8 311.8 218.8 293.6 176.6"
                fill="#fff"
              />
            </clipPath>
          </defs>

          <rect
            style="display: none"
            fill="url(#grad1)"
            x="0"
            y="0"
            width="900"
            height="1024"
          />

          <g id="shadow_obj" style="filter: url(#dropshadow)">
            <path
              d="M576.55,832.6l0,.06-.13-.06H66.35v42.2h156.7v36.4l17.2,17.2h577.2l15.3-17.2V832.6H576.55Zm214,51.7H598.85l.24.56-.34-.56H267.15v-9.5h523.4Z"
              fill="#f4c912"
            />
            <polygon
              points="530.16 725.6 81.35 725.6 66.55 740.4 66.55 804.8 81.35 821.5 571.24 821.5 571.55 821.5 571.65 821.7 832.75 821.7 832.75 777.6 552.85 777.6 552.75 777.4 552.35 777.4 108.15 777.4 108.15 767.8 548.24 767.8 548.55 767.8 832.75 767.8 832.75 725.6 530.35 725.6 530.16 725.6"
              fill="#f4c912"
            />
            <polygon
              points="790.55 579.6 635.35 579.6 619.95 595 619.95 621.8 619.95 623.7 619.95 631.4 489.85 631.4 489.8 631.4 107.95 631.4 107.95 621.8 485.61 621.8 485.65 621.9 485.73 621.9 610.35 621.9 610.35 579.7 471 579.7 470.96 579.6 467.61 579.6 81.15 579.6 66.35 594.4 66.35 658.8 81.15 675.5 508.69 675.5 508.85 675.5 646.85 675.5 662.15 658.2 662.15 631.4 662.15 629.5 662.15 621.8 790.55 621.8 790.55 631.4 671.75 631.4 671.75 675.5 790.55 675.5 817.45 675.5 832.75 658.2 832.75 631.4 832.75 621.8 832.75 595 817.45 579.6 790.55 579.6"
              fill="#f4c912"
            />
            <polygon
              points="444.35 525.6 66.65 525.6 66.65 567.8 271.45 567.8 271.45 568.2 272.45 568.2 272.45 567.8 462.55 567.8 462.75 567.8 832.75 567.8 832.75 525.6 444.48 525.6 444.35 525.6"
              fill="#f4c912"
            />
            <polygon
              points="399.49 420.6 398.95 420.6 66.05 420.6 66.05 476.2 107.65 476.2 107.65 462.8 417.15 462.8 417.57 462.8 612.85 462.8 612.85 476.2 655.05 476.2 655.05 462.8 790.55 462.8 790.55 476.2 832.75 476.2 832.75 420.6 399.52 420.6 399.49 420.6"
              fill="#f4c912"
            />
            <polygon
              points="317.15 230.6 66.85 230.6 66.85 272.8 271.45 272.8 271.45 273 272.45 273 272.45 272.8 335.35 272.8 336.17 272.8 380.35 272.8 792.55 265.1 792.55 274.7 401.45 280.5 339.94 281.59 339.15 281.6 81.95 286.2 81.55 286.2 66.75 299.2 66.75 340.3 81.55 351.4 370.25 356.6 371.35 356.6 371.45 356.9 372.21 356.92 401.45 357.5 792.55 363.2 792.55 372.8 380.35 365.1 375.71 365.1 375.05 365.1 66.15 364.6 66.15 408.7 271.45 408.7 271.45 409.1 272.45 409.1 272.45 408.7 394.05 409.2 394.61 409.2 832.75 409.2 832.75 374.7 832.75 365.1 832.75 340.2 815.55 324.9 357.94 323.6 357.55 323.6 357.45 323.3 108.35 322.7 108.35 315 353.55 314.3 353.95 314.3 815.55 313.1 832.75 299.6 832.75 272.8 832.75 265.1 832.75 230.6 318.09 230.6 317.15 230.6"
              fill="#f4c912"
            />
            <polygon
              points="294.05 176.6 65.95 176.6 65.95 218.8 271.45 218.8 271.45 219.3 272.45 219.3 272.45 218.8 312.25 218.8 312.57 218.8 313.04 218.8 832.75 218.8 832.75 176.6 294.96 176.6 294.05 176.6"
              fill="#f4c912"
            />
            <polygon
              points="790.55 96.6 790.55 110.1 266.25 109.6 265.55 109.6 66.85 109.6 66.85 151.7 283.35 152.2 284.5 152.2 790.55 152.2 790.55 167.6 832.75 167.6 832.75 152.2 832.75 110.1 832.75 96.6 790.55 96.6"
              fill="#f4c912"
            />
          </g>

          <g id="parts">
            <polygon
              id="r11"
              points="594.75 874.8 662.77 874.8 790.55 874.8 790.55 884.3 598.85 884.3 599.09 884.87 598.75 884.3 598.15 884.3 617.04 928.4 617.85 928.4 625.56 928.4 790.55 928.4 817.45 928.4 832.75 911.2 832.75 884.3 832.75 874.8 832.75 832.6 790.55 832.6 576.55 832.6 576.58 832.66 576.45 832.6 576 832.6 594.08 874.8 594.75 874.8"
              fill="#f4c912"
            />
            <polygon
              id="r10"
              points="571.55 821.5 571.65 821.7 832.75 821.7 832.75 777.6 552.85 777.6 552.75 777.4 552.35 777.4 571.24 821.5 571.55 821.5"
              fill="#f4c912"
            />
            <polygon
              id="l10"
              points="66.35 874.8 223.05 874.8 223.05 911.2 240.25 928.4 617.04 928.4 598.15 884.3 267.15 884.3 267.15 874.8 594.08 874.8 576 832.6 66.35 832.6 66.35 874.8"
              fill="#f4c912"
            />
            <polygon
              id="r9"
              points="548.55 767.8 832.75 767.8 832.75 725.6 530.35 725.6 530.16 725.6 548.24 767.8 548.55 767.8"
              fill="#f4c912"
            />
            <polygon
              id="l9"
              points="66.55 740.4 66.55 804.8 81.35 821.5 571.24 821.5 552.35 777.4 108.15 777.4 108.15 767.8 548.24 767.8 530.16 725.6 81.35 725.6 66.55 740.4"
              fill="#f4c912"
            />
            <polygon
              id="r8"
              points="508.85 675.5 646.85 675.5 662.15 658.2 662.15 631.4 662.15 629.5 662.15 621.8 790.55 621.8 790.55 631.4 671.75 631.4 671.75 675.5 790.55 675.5 817.45 675.5 832.75 658.2 832.75 631.4 832.75 621.8 832.75 595 817.45 579.6 790.55 579.6 635.35 579.6 619.95 595 619.95 621.8 619.95 623.7 619.95 631.4 489.85 631.4 489.8 631.4 508.69 675.5 508.85 675.5"
              fill="#f4c912"
            />
            <polygon
              id="l8"
              points="66.35 594.4 66.35 658.8 81.15 675.5 508.69 675.5 489.8 631.4 107.95 631.4 107.95 621.8 485.61 621.8 485.65 621.9 485.73 621.9 467.61 579.6 81.15 579.6 66.35 594.4"
              fill="#f4c912"
            />
            <polygon
              id="r7"
              points="610.35 621.9 610.35 579.7 471 579.7 470.96 579.6 467.61 579.6 485.73 621.9 610.35 621.9"
              fill="#f4c912"
            />
            <polygon
              id="l7"
              points="66.65 525.6 66.65 567.8 271.45 567.8 271.45 568.2 272.45 568.2 272.45 567.8 462.55 567.8 444.48 525.6 444.35 525.6 66.65 525.6"
              fill="#f4c912"
            />
            <polygon
              id="r6"
              points="462.75 567.8 832.75 567.8 832.75 525.6 444.48 525.6 462.55 567.8 462.75 567.8"
              fill="#f4c912"
            />
            <polygon
              id="l6"
              points="66.05 420.6 66.05 476.2 107.65 476.2 107.65 462.8 417.15 462.8 417.57 462.8 399.49 420.6 398.95 420.6 66.05 420.6"
              fill="#f4c912"
            />
            <polygon
              id="r5"
              points="612.85 462.8 612.85 476.2 655.05 476.2 655.05 462.8 790.55 462.8 790.55 476.2 832.75 476.2 832.75 420.6 399.52 420.6 399.49 420.6 417.57 462.8 612.85 462.8"
              fill="#f4c912"
            />
            <polygon
              id="l5"
              points="375.05 365.1 66.15 364.6 66.15 408.7 271.45 408.7 271.45 409.1 272.45 409.1 272.45 408.7 394.05 409.2 394.61 409.2 375.71 365.1 375.05 365.1"
              fill="#f4c912"
            />
            <polygon
              id="r4"
              points="401.45 357.5 792.55 363.2 792.55 372.8 380.35 365.1 375.71 365.1 394.61 409.2 832.75 409.2 832.75 374.7 832.75 365.1 832.75 340.2 815.55 324.9 357.94 323.6 372.21 356.92 401.45 357.5"
              fill="#f4c912"
            />
            <polygon
              id="l4"
              points="339.15 281.6 81.95 286.2 81.55 286.2 66.75 299.2 66.75 340.3 81.55 351.4 370.25 356.6 371.35 356.6 371.45 356.9 372.21 356.92 357.94 323.6 357.55 323.6 357.45 323.3 108.35 322.7 108.35 315 353.55 314.3 353.95 314.3 339.94 281.59 339.15 281.6"
              fill="#f4c912"
            />
            <polygon
              id="r3"
              points="380.35 272.8 792.55 265.1 792.55 274.7 401.45 280.5 339.94 281.59 353.95 314.3 815.55 313.1 832.75 299.6 832.75 272.8 832.75 265.1 832.75 230.6 318.09 230.6 336.17 272.8 380.35 272.8"
              fill="#f4c912"
            />
            <polygon
              id="l3"
              points="66.85 230.6 66.85 272.8 271.45 272.8 271.45 273 272.45 273 272.45 272.8 335.35 272.8 336.17 272.8 318.09 230.6 317.15 230.6 66.85 230.6"
              fill="#f4c912"
            />
            <polygon
              id="r2"
              points="832.75 218.8 832.75 176.6 294.96 176.6 313.04 218.8 832.75 218.8"
              fill="#f4c912"
            />
            <polygon
              id="l2"
              points="65.95 176.6 65.95 218.8 271.45 218.8 271.45 219.3 272.45 219.3 272.45 218.8 312.25 218.8 312.57 218.8 313.04 218.8 294.96 176.6 294.05 176.6 65.95 176.6"
              fill="#f4c912"
            />
            <polygon
              id="r1"
              points="790.55 96.6 790.55 110.1 266.25 109.6 284.5 152.2 790.55 152.2 790.55 167.6 832.75 167.6 832.75 152.2 832.75 110.1 832.75 96.6 790.55 96.6"
              fill="#f4c912"
            />
            <polygon
              id="l1"
              points="265.55 109.6 66.85 109.6 66.85 151.7 283.35 152.2 284.5 152.2 266.25 109.6 265.55 109.6"
              fill="#f4c912"
            />
          </g>

          <g id="mask">
            <path
              d="M576.55,832.6l0,.06-.13-.06H66.35v42.2h156.7v36.4l17.2,17.2h577.2l15.3-17.2V832.6H576.55Zm214,51.7H598.85l.24.56-.34-.56H267.15v-9.5h523.4Z"
              fill="#f4c912"
            />
            <polygon
              points="530.16 725.6 81.35 725.6 66.55 740.4 66.55 804.8 81.35 821.5 571.24 821.5 571.55 821.5 571.65 821.7 832.75 821.7 832.75 777.6 552.85 777.6 552.75 777.4 552.35 777.4 108.15 777.4 108.15 767.8 548.24 767.8 548.55 767.8 832.75 767.8 832.75 725.6 530.35 725.6 530.16 725.6"
              fill="#f4c912"
            />
            <polygon
              points="790.55 579.6 635.35 579.6 619.95 595 619.95 621.8 619.95 623.7 619.95 631.4 489.85 631.4 489.8 631.4 107.95 631.4 107.95 621.8 485.61 621.8 485.65 621.9 485.73 621.9 610.35 621.9 610.35 579.7 471 579.7 470.96 579.6 467.61 579.6 81.15 579.6 66.35 594.4 66.35 658.8 81.15 675.5 508.69 675.5 508.85 675.5 646.85 675.5 662.15 658.2 662.15 631.4 662.15 629.5 662.15 621.8 790.55 621.8 790.55 631.4 671.75 631.4 671.75 675.5 790.55 675.5 817.45 675.5 832.75 658.2 832.75 631.4 832.75 621.8 832.75 595 817.45 579.6 790.55 579.6"
              fill="#f4c912"
            />
            <polygon
              points="444.35 525.6 66.65 525.6 66.65 567.8 271.45 567.8 271.45 568.2 272.45 568.2 272.45 567.8 462.55 567.8 462.75 567.8 832.75 567.8 832.75 525.6 444.48 525.6 444.35 525.6"
              fill="#f4c912"
            />
            <polygon
              points="399.49 420.6 398.95 420.6 66.05 420.6 66.05 476.2 107.65 476.2 107.65 462.8 417.15 462.8 417.57 462.8 612.85 462.8 612.85 476.2 655.05 476.2 655.05 462.8 790.55 462.8 790.55 476.2 832.75 476.2 832.75 420.6 399.52 420.6 399.49 420.6"
              fill="#f4c912"
            />
            <polygon
              points="317.15 230.6 66.85 230.6 66.85 272.8 271.45 272.8 271.45 273 272.45 273 272.45 272.8 335.35 272.8 336.17 272.8 380.35 272.8 792.55 265.1 792.55 274.7 401.45 280.5 339.94 281.59 339.15 281.6 81.95 286.2 81.55 286.2 66.75 299.2 66.75 340.3 81.55 351.4 370.25 356.6 371.35 356.6 371.45 356.9 372.21 356.92 401.45 357.5 792.55 363.2 792.55 372.8 380.35 365.1 375.71 365.1 375.05 365.1 66.15 364.6 66.15 408.7 271.45 408.7 271.45 409.1 272.45 409.1 272.45 408.7 394.05 409.2 394.61 409.2 832.75 409.2 832.75 374.7 832.75 365.1 832.75 340.2 815.55 324.9 357.94 323.6 357.55 323.6 357.45 323.3 108.35 322.7 108.35 315 353.55 314.3 353.95 314.3 815.55 313.1 832.75 299.6 832.75 272.8 832.75 265.1 832.75 230.6 318.09 230.6 317.15 230.6"
              fill="#f4c912"
            />
            <polygon
              points="294.05 176.6 65.95 176.6 65.95 218.8 271.45 218.8 271.45 219.3 272.45 219.3 272.45 218.8 312.25 218.8 312.57 218.8 313.04 218.8 832.75 218.8 832.75 176.6 294.96 176.6 294.05 176.6"
              fill="#f4c912"
            />
            <polygon
              points="790.55 96.6 790.55 110.1 266.25 109.6 265.55 109.6 66.85 109.6 66.85 151.7 283.35 152.2 284.5 152.2 790.55 152.2 790.55 167.6 832.75 167.6 832.75 152.2 832.75 110.1 832.75 96.6 790.55 96.6"
              fill="#f4c912"
            />
          </g>

          <g id="text-contents" clip-path="url(#cp)">
            <g id="content_1" transform="translate(65 95)">
              <rect
                x="2.45"
                y="8.03"
                width="216.4"
                height="55.19"
                fill="#FFFFFF"
              />
              <text
                transform="translate(190 41)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(0).label }}</tspan>
                <tspan>{{ getTextByIndex(0).value }}</tspan>
              </text>
            </g>

            <g id="content_2" transform="translate(65 162)">
              <rect
                x="2.45"
                y="8.03"
                width="244"
                height="55.19"
                fill="#FFFFFF"
              />
              <text
                transform="translate(220 41)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(1).label }}</tspan>
                <tspan>{{ getTextByIndex(1).value }}</tspan>
              </text>
            </g>

            <g id="content_3" transform="translate(65 215)">
              <rect
                x="2.45"
                y="8.03"
                width="268"
                height="55.19"
                fill="#FFFFFF"
              />
              <text
                transform="translate(250 41)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(2).label }}</tspan>
                <tspan>{{ getTextByIndex(2).value }}</tspan>
              </text>
            </g>

            <g id="content_4" transform="translate(65 270)">
              <rect x="2.45" y="8.03" width="305" height="80" fill="#FFFFFF" />
              <text
                transform="translate(270 32)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(3).label }}</tspan>
                <tspan>{{ getTextByIndex(3).value }}</tspan>
              </text>

              <text
                transform="translate(288 75)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(4).label }}</tspan>
                <tspan>{{ getTextByIndex(4).value }}</tspan>
              </text>
            </g>

            <g id="content_5" transform="translate(65 355)">
              <rect x="2.45" y="8.03" width="330" height="45" fill="#FFFFFF" />
              <text
                transform="translate(305 36)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(5).label }}</tspan>
                <tspan>{{ getTextByIndex(5).value }}</tspan>
              </text>
            </g>

            <g id="content_6" transform="translate(65 410)">
              <rect x="2" y="8.03" width="350" height="60" fill="#FFFFFF" />
              <text
                transform="translate(330 36)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(6).label }}</tspan>
                <tspan>{{ getTextByIndex(6).value }}</tspan>
              </text>
            </g>

            <g id="content_7" transform="translate(65 515)">
              <rect x="2" y="8.03" width="395" height="45" fill="#FFFFFF" />
              <text
                transform="translate(375 36)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(7).label }}</tspan>
                <tspan>{{ getTextByIndex(7).value }}</tspan>
              </text>
            </g>

            <g id="content_8" transform="translate(65 570)">
              <rect x="2" y="8.03" width="444" height="98" fill="#FFFFFF" />
              <text
                transform="translate(397 36)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(8).label }}</tspan>
                <tspan>{{ getTextByIndex(8).value }}</tspan>
              </text>

              <text
                transform="translate(420 88)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(9).label }}</tspan>
                <tspan>{{ getTextByIndex(9).value }}</tspan>
              </text>
            </g>

            <g id="content_9" transform="translate(65 715)">
              <rect x="2" y="8.03" width="505" height="98" fill="#FFFFFF" />
              <text
                transform="translate(460 36)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(10).label }}</tspan>
                <tspan>{{ getTextByIndex(10).value }}</tspan>
              </text>

              <text
                transform="translate(480 88)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(11).label }}</tspan>
                <tspan>{{ getTextByIndex(11).value }}</tspan>
              </text>
            </g>

            <g id="content_10" transform="translate(65 823)">
              <rect x="2" y="8.03" width="550" height="98" fill="#FFFFFF" />
              <text
                transform="translate(505 34)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(12).label }}</tspan>
                <tspan>{{ getTextByIndex(12).value }}</tspan>
              </text>

              <text
                transform="translate(525 88)"
                text-anchor="end"
                font-size="14"
                font-family="Nunito Sans"
              >
                <tspan class="strong">{{ getTextByIndex(13).label }}</tspan>
                <tspan>{{ getTextByIndex(13).value }}</tspan>
              </text>
            </g>
          </g>

          <g id="outlines">
            <polygon
              id="o1"
              points="790.55 96.6 790.55 110.1 265.55 109.6 66.85 109.6 66.85 151.7 283.35 152.2 790.55 152.2 790.55 167.6 832.75 167.6 832.75 152.2 832.75 110.1 832.75 96.6 790.55 96.6"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <polygon
              id="o3"
              points="66.85 230.6 66.85 272.8 271.45 272.8 271.45 273 272.45 273 272.45 272.8 335.35 272.8 380.35 272.8 792.55 265.1 792.55 274.7 401.45 280.5 339.15 281.6 81.95 286.2 81.55 286.2 66.75 299.2 66.75 340.3 81.55 351.4 370.25 356.6 371.35 356.6 371.45 356.9 401.45 357.5 792.55 363.2 792.55 372.8 380.35 365.1 375.05 365.1 66.15 364.6 66.15 408.7 271.45 408.7 271.45 409.1 272.45 409.1 272.45 408.7 394.05 409.2 832.75 409.2 832.75 374.7 832.75 365.1 832.75 340.2 815.55 324.9 357.55 323.6 357.45 323.3 108.35 322.7 108.35 315 353.55 314.3 815.55 313.1 832.75 299.6 832.75 272.8 832.75 265.1 832.75 230.6 317.15 230.6 66.85 230.6"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <polygon
              id="o5"
              points="66.65 525.6 66.65 567.8 271.45 567.8 271.45 568.2 272.45 568.2 272.45 567.8 462.75 567.8 832.75 567.8 832.75 525.6 444.35 525.6 66.65 525.6"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <polygon
              id="o6"
              points="646.85 675.5 662.15 658.2 662.15 631.4 662.15 629.5 662.15 621.8 790.55 621.8 790.55 631.4 671.75 631.4 671.75 675.5 790.55 675.5 817.45 675.5 832.75 658.2 832.75 631.4 832.75 621.8 832.75 595 817.45 579.6 790.55 579.6 635.35 579.6 619.95 595 619.95 621.8 619.95 623.7 619.95 631.4 489.85 631.4 107.95 631.4 107.95 621.8 485.61 621.8 485.65 621.9 610.35 621.9 610.35 579.7 471 579.7 470.96 579.6 81.15 579.6 66.35 594.4 66.35 658.8 81.15 675.5 508.85 675.5 646.85 675.5"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <polygon
              id="o7"
              points="81.35 725.6 66.55 740.4 66.55 804.8 81.35 821.5 571.55 821.5 571.65 821.7 832.75 821.7 832.75 777.6 552.85 777.6 552.75 777.4 108.15 777.4 108.15 767.8 548.55 767.8 832.75 767.8 832.75 725.6 530.35 725.6 81.35 725.6"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <path
              id="o8"
              d="M576.55,832.6l0,.06-.13-.06H66.35v42.2h156.7v36.4l17.2,17.2h577.2l15.3-17.2V832.6H576.55Zm214,51.7H598.85l.24.56-.34-.56H267.15v-9.5h523.4Z"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <polygon
              id="o2"
              points="65.95 176.6 65.95 218.8 271.45 218.8 271.45 219.3 272.45 219.3 272.45 218.8 312.25 218.8 312.57 218.8 832.75 218.8 832.75 176.6 294.05 176.6 65.95 176.6"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <polygon
              id="o4"
              points="398.95 420.6 66.05 420.6 66.05 476.2 107.65 476.2 107.65 462.8 417.15 462.8 612.85 462.8 612.85 476.2 655.05 476.2 655.05 462.8 790.55 462.8 790.55 476.2 832.75 476.2 832.75 420.6 399.52 420.6 398.95 420.6"
              fill="none"
              stroke="#f9c336"
              stroke-miterlimit="10"
              stroke-width="2"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import GenderToggle from "./GenderToggle";
import Button from "@/components/form/Button";
import gsap from "gsap";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";

//import {saveSvgAsPng} from 'save-svg-as-png';

export default {
  components: { Button, GenderToggle },
  name: "DataInteraction",
  mixins: [DownloadHelperMixin],
  data() {
    return {
      buttonsVisible: false,
      timeline: undefined,
      userAge: 21,
      currentGender: undefined,
      contents: [
        {
          label: "Sleep [years]",
          ratio_woman: 0.3342465753,
          ratio_men: 0.3342465753
        },
        {
          label: "Time at work [years]",
          ratio_woman: 0.1693348737,
          ratio_men: 0.1693361433
        },
        {
          label: "Watching TV [years]",
          ratio_woman: 0.1153853771,
          ratio_men: 0.1153846154
        },
        {
          label: "Trying to fall asleep [years]",
          ratio_woman: 0.08974418221,
          ratio_men: 0.08974358974
        },
        {
          label: "Eating [years]",
          ratio_woman: 0.05769268856,
          ratio_men: 0.05769230769
        },
        {
          label: "Holidays [years]",
          ratio_woman: 0.03951477141,
          ratio_men: 0.03951527924
        },
        {
          label: "Social Media [years]",
          ratio_woman: 0.03846179237,
          ratio_men: 0.03846153846
        },
        {
          label: "Exercising [years]",
          ratio_woman: 0.01703581449,
          ratio_men: 0.01703547594
        },
        {
          label: "Being Romantic [years]",
          ratio_woman: 0.0138735765,
          ratio_men: 0.0138742536
        },
        {
          label: "Socializing [years]",
          ratio_woman: 0.01292622545,
          ratio_men: 0.0129258869
        },
        {
          label: "Unpaid Overtime [years]",
          ratio_woman: 0.01496286516,
          ratio_men: 0.01496311907
        },
        {
          label: "Queuing [years]",
          ratio_woman: 0.008255487704,
          ratio_men: 0.008254302775
        },
        {
          label: "Getting ready [years]",
          ratio_woman: 0.004489189635,
          ratio_men: 0.001615735862
        },
        {
          label: "Laughing [years]",
          ratio_woman: 0.004040270672,
          ratio_men: 0.004039339656
        }
      ]
    };
  },
  methods: {
    onRetryClick() {
      this.buttonsVisible = false;
      gsap.to(this.$refs.intro, {
        delay: 1,
        duration: 1,
        scale: 1,
        autoAlpha: 1
      });

      this.timeline.pause();
      gsap.to(this.timeline, { duration: 2, progress: 0 });
    },
    onDownloadClick() {
      this.createPngFromSvg(this.$refs.illustration, "YourLifeInNumbers.png");
    },
    onGenderToggle(gender) {
      this.currentGender = gender;
    },
    onTimelineComplete(){
      this.buttonsVisible = true;
      this.$nextTick(()=>{
        gsap.set(this.$refs.buttons,{autoAlpha:0});
        gsap.to(this.$refs.buttons,{duration:.8,autoAlpha:1});

      });
    },
    initAnimation() {
      var tl = gsap.timeline({ repeat: 0 ,onComplete: this.onTimelineComplete});
      this.timeline = tl;

      const letters = this.$refs.illustration.getElementById("parts");
      const shadow = this.$refs.illustration.getElementById("shadow_obj");
      const outlines = this.$refs.illustration.getElementById("outlines");
      const contents = this.$refs.illustration.getElementById("text-contents");

      const letterParts = Array.from(letters.children);
      letterParts.forEach(element => {
        const direction = element.id.substr(0, 1);
        let targetX = 500 + Math.random() * 200;

        if (direction === "l") targetX = -targetX;

        gsap.set(element, { x: targetX });
        tl.to(
          element,
          {
            ease: "Expo.easeInOut",
            duration: 1,
            delay: Math.random() * 0.2,
            x: 0
          },
          0
        );

        //intro fadeIn
        let initX = "-=50";
        if (direction === "l") initX = "+=50";
        gsap.from(element, { x: initX, alpha: 0, duration: 2 });
      });

      tl.to(shadow, { autoAlpha: 1, delay: 1 }, 0);
      tl.to(outlines, { autoAlpha: 1, delay: 1 }, 0);

      const textChildren = Array.from(contents.children);

      let delay = 0;

      const textElements = [];
      textChildren.forEach(item => {
        gsap.set(item, { autoAlpha: 0, x: "-300" });
        tl.to(item, { autoAlpha: 1, x: "65", delay: 1 + delay * 0.1 }, 0);

        gsap.set(item.querySelectorAll("text"), { autoAlpha: 0 });

        const subItems = Array.from(item.querySelectorAll("text"));

        subItems.forEach(subitem => {
          textElements.push(subitem);
        });

        delay++;
      });

      delay = 0;
      textElements.forEach(text => {
        tl.to(text, { autoAlpha: 1, delay: 1.5 + delay * 0.1 }, 0);
        delay++;
      });

      //gsap.to("#f1",{x:-100});

      gsap.from(this.$refs.intro, { duration: 1, scale: 0.8, autoAlpha: 0 });

      tl.pause();


    },
    calculate() {
      if (parseInt(this.$refs.input.value) > 1) {
        this.userAge = parseInt(this.$refs.input.value);

        gsap
          .to(this.$refs.intro, { duration: 1, scale: 0.8, autoAlpha: 0 });
          
        this.timeline.play();
      } else {
        const toast = {
          label: "Please fill in your age",
          /*body: "Für dieses Feature musst du angemeldet sein.",*/
          type: "warning",
          life: "5"
        };

        this.$store.getters.toastManager.add(toast);
      }
    },
    getTextByIndex(index) {
      return this.getDataLabel(this.contents[index]);
    },

    calculateTime(d) {
      const days = Math.floor(d);
      const daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";
      return daysDisplay;
      /*
      var years = Math.floor(d / 365);
      var months = Math.floor((d % 365) / 30);
      var days = Math.floor((d % 365) % 30);

      var yearsDisplay =
        years > 0 ? years + (years == 1 ? " year, " : " years, ") : "";
      var monthsDisplay =
        months > 0 ? months + (months == 1 ? " month, " : " months, ") : "";
      var daysDisplay = days > 0 ? days + (days == 1 ? " day" : " days") : "";
      return yearsDisplay + monthsDisplay + daysDisplay;*/
    },
    getDataLabel(knot) {
      const yearsWoman = this.calculateTime(
        this.userAge * knot.ratio_woman * 365
      );
      const yearsMen = this.calculateTime(this.userAge * knot.ratio_men * 365);

      let yearsAll = this.calculateTime(
        ((this.userAge * knot.ratio_men + this.userAge * knot.ratio_woman) /
          2) *
          365
      );

      yearsWoman;
      yearsMen;

      if (!this.currentGender) {
        yearsAll = yearsMen;
      } else {
        yearsAll = yearsWoman;
      }

      let returnLabel = knot.label.split("[years]")[0];
      const returnValue = knot.label.split("[years]")[1] + yearsAll;

      return { label: returnLabel, value: returnValue };
    }
  },
  mounted() {
    this.$nextTick(this.initAnimation);
  }
};
</script>
