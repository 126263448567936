<template>
  <div
    class="gender-toggle"
    :class="{ 'gender-toggle--toggled': toggled }"
    @click="onClick"
  >

    
    <div class="gender-toggle__knob" ref="knob">

      <div class="gender-toggle__icon_female" ref="icon1">
        <inline-svg
          :src="require('../../../assets/svg/icon_gendertoggle_female.svg')"
        />
      </div>

      <div class="gender-toggle__icon_male" ref="icon2">
        <inline-svg
          :src="require('../../../assets/svg/icon_gendertoggle_male.svg')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "GenderToggle",
  data() {
    return {
      toggled: false
    };
  },
  methods: {
    onClick(e) {
      e;
      this.toggled = !this.toggled;

      let targetPos = 0;
      let tRotation = 0;

      if (this.toggled) {
        targetPos = 63;
        tRotation = 360;
      }

      gsap.to(this.$refs.knob, {
        rotation: tRotation,
        duration: 0.5,
        ease: "Expo.easeOut",
        x: targetPos
      });

      if (this.toggled) {
        gsap.to(this.$refs.icon1, { alpha: 1 });
        gsap.to(this.$refs.icon2, { alpha: 0 });
      } else {
        gsap.to(this.$refs.icon1, { alpha: 0 });
        gsap.to(this.$refs.icon2, { alpha: 1 });
      }
      
      this.$emit('toggle',this.toggled);

    }

    
  }
};
</script>
